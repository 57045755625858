import React from 'react'
import { BackTop, Divider, Row, Col } from 'antd'

import '../styles/Body.css'

const Body = () => {
    return (
        <div id="Body">
            <Divider><h1 id="about">About</h1></Divider>
            <div style={{ textAlign: 'center' }}>
                <p>I'm a third generation American with family lines tracing back to the red side of Liverpool, UK. By the age of 18 I was one of the top 25 amateur motocross riders in the state of Utah. I decided to retire from competitive racing to launch a motocross graphics company. At Trevex Graphics I filled a number of non-technical roles. We made several mistakes and the great recession in 2008 was the nail in the coffin for Trevex so I decided to attend college at Utah State University. While in school I volunteered for student groups and consulted for local small businesses.</p>

                <p>After graduation I spent a summer travelling before starting to work for Energy Management in their ecommerce department. When I joined we were a small department of two people. Over the course of 2.5 years we were able to grow from a side project to a major company division and increase revenue by 450%.</p>

                <p>Back in 2015 I decided it was time for a new challenge and joined Instructure. I started on the implementation team before a couple promotions and eventually moved to the product management team. From there I've helped a couple of startups before moving to Bill.com and Salesforce via acquisition.</p>
            </div>

            <Divider><h1 id="work">Work</h1></Divider>

            <h2 id="salesforce">Spiff / Salesforce</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Senior Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2023 - Present</p>
                </Col>
            </Row>
            <ul>
                <li>Managed the development of the partner self-service program to unlock over $25mm of pre-launch pipeline (it’s significantly larger today)</li>
                <li>Negotiated and managed a number of multi-team/multi-department projects like instance environments</li>
            </ul>
            
            <h2 id="bill">Divvy / Bill.com</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Senior Staff Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2021 - 2023</p>
                </Col>
            </Row>
            <p>Managed the development of the company's integration with Divvy ($2.5 billion acquisition)</p>
            
            <h2 id="bbot">Bbot</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2020 - 2021</p>
                </Col>
            </Row>
            <p>Acquired by DoorDash</p>
            
            <h2 id="taskeasy">TaskEasy</h2>

            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Technical Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2020 - 2020</p>
                </Col>
            </Row>
            <p>Managed the initial phase of a major product redevlopment project leading to the company's 2022 acquisition by WorkWave.</p>
            
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h2 id="instructure" className="">Instructure</h2>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md pt-3">
                    <p class="position-dates">2015 - 2020</p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Technical Product Manager</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Manager, Technical Consulting</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Manager, K12 Consulting</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Principal Implementation Consultant</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Implementation Consultant</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>

            <h2 id="emc">Energy Management Corporation</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Business Development Specialist</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2012 - 2015</p>
                </Col>
            </Row>
            <p>Developed a cost effective marketing plan with a focus on organic search traffic which increased ecommerce revenue by over 350% in two years</p>

            <h2 id="trevex">Trevex Graphics</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Co-Founder</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates">2007 - 2010</p>
                </Col>
            </Row>
            <p>Trevex Graphics was an online design platform and graphics company. Before Trevex customers would order graphics over the phone or through an online form without seeing the end result until it arrived.</p>

            <p>Ultimately we failed to find product-market fit and closed. But not before being featured in several Supercross races and the X Games.</p>

            <Divider><h1 id="work">Education</h1></Divider>

            <h2 id="usu">Utah State University</h2>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h3>Jon M. Huntsman School of Business</h3>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <ul>
                <li>Dual B.S. - Marketing &amp; Entrepreneurship</li>
                <li>Gary and Karen Black Scholarship Recipient (thank you)</li>
                <li>Dean's List student</li>
            </ul>

            <h3>Entrepreneurship Club</h3>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Executive Vice President</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                    <h4>Vice President of Marketing</h4>
                </Col>
                <Col xs={24} sm={24} md={12} lg={8} xl={8} className="text-right-md">
                    <p class="position-dates"></p>
                </Col>
            </Row>

            <BackTop />
        </div>
    )
}

export default Body
